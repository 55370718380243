// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconMessages } from '@tabler/icons';
import colors from '../../assets/scss/_themes-vars.module.scss';

// type
import { NavItemType } from 'types';
import { useUnreadCount } from '../../hooks/useUnreadCount';
import { useCurrentUser } from '../../hooks/useUser.hooks';

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const MessagesTitle = () => {
    const userState = useCurrentUser();
    const unreadCount = useUnreadCount(userState.ready ? userState.user?.id : undefined)?? 0;

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <FormattedMessage id="Messages" />
            <span style={{
                backgroundColor: colors.paper,
                color: colors.secondaryMain,
                border: `1px solid ${colors.secondaryMain}`,
                borderRadius: '4px',
                padding: '2px 6px',
                fontSize: '10px',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                letterSpacing: '0.5px'
            }}>
                Beta
            </span>
            {unreadCount > 0 && (
                    <span style={{
                        backgroundColor: colors.secondaryMain,
                        color: colors.primaryLight,
                        borderRadius: '50%',
                        padding: '2px 6px',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        minWidth: '20px',
                    }}>
                        {unreadCount}
                    </span>
                )}
            </div>
    );
};

const messages: NavItemType = {
    id: 'messages',
    title: <MessagesTitle />,
    type: 'group',
    icon: IconMessages,
    url: '/messages',
    target: false
};

export default messages;
