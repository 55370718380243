import axios from 'utils/axios';
import { useState, useEffect } from 'react';

interface FoodCategory {
    id: number;
    name: string;
}

export function useFoodCategories() {
    const [foodCategories, setFoodCategories] = useState<FoodCategory[]>([]);

    useEffect(() => {
        axios
            .get('/berryAPI/food-categories')
            .then((response) => {
                setFoodCategories(response.data);
            })
            .catch((error) => {
                console.error('Error fetching food categories:', error);
                setFoodCategories([]);
            });
    }, []);

    return { foodCategories };
}
