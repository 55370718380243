// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconRoute2 } from '@tabler/icons';

// type
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const icons = {
    IconRoute2
};
const routesWithHH: NavItemType = {
    id: 'routes-section',
    // title: <FormattedMessage id="routes" />,
    // caption: <FormattedMessage id="routes-caption" />,
    icon: icons.IconRoute2,
    type: 'group',
    children: [
        {
            id: 'routes',
            title: <FormattedMessage id="Routes" />,
            type: 'collapse',
            icon: icons.IconRoute2,
            children: [
                {
                    id: 'view-routes',
                    title: <FormattedMessage id="Manage routes" />,
                    type: 'item',
                    url: '/routes',
                    target: false
                },
                {
                    id: 'view-route-instances-by-date',
                    title: <FormattedMessage id="View routes by date" />, //shortened so it fits in sidebar
                    type: 'item',
                    url: '/route-instances-by-date',
                    target: false
                },
                {
                    id: 'create-routes',
                    title: <FormattedMessage id="Create routes" />,
                    type: 'item',
                    url: '/routes/create',
                    target: false
                },
                {
                    id: 'create-hh-pickup',
                    title: <FormattedMessage id="Create home harvest pickup-ready donation" />,
                    type: 'item',
                    url: '/route-instance/home-harvest/pickup',
                    target: false
                },
                {
                    id: 'create-hh-harvest',
                    title: <FormattedMessage id="Create home harvest harvestable donation" />,
                    type: 'item',
                    url: '/route-instance/home-harvest/harvest',
                    target: false
                }
            ]
        }
    ]
};

export default routesWithHH;
