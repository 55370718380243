// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconList } from '@tabler/icons';

// type
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const icons = {
    IconList
};
const routes: NavItemType = {
    id: 'organizations-section',
    icon: icons.IconList,
    type: 'group',
    children: [
        {
            id: 'organizations',
            title: <FormattedMessage id="Organizations" />,
            type: 'collapse',
            icon: icons.IconList,
            children: [
                {
                    id: 'organizations',
                    title: <FormattedMessage id="Manage Organizations" />,
                    type: 'item',
                    url: '/organizations',
                    target: false
                }
            ]
        }
    ]
};

export default routes;
