// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconAward } from '@tabler/icons';

// type
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const icons = {
    IconAward
};
const achievements: NavItemType = {
  id: 'achievementsSection',
  title: <FormattedMessage id="Achievements" />,
  type: 'group',
  children: [
              {
                  id: 'achievements',
                  title: <FormattedMessage id="Achievements" />,
                  type: 'item',
                  icon: icons.IconAward,
                  url: '/achievements',
                  target: false
              }
          ]
};

export default achievements;
