import { useState, useEffect } from 'react';
import axios from '../utils/axios';

/**
 * Hook to fetch the number of unread conversations for a given user.
 * 
 * @param {number} userId - The ID of the user to fetch unread conversations for.
 * @returns {number} The number of unread conversations for the user.
 */
export const useUnreadCount = (userId: number) => {
    const [unreadCount, setUnreadCount] = useState(0);

    const fetchUnreadCount = async () => {
        if (!userId)
        {
          return;
        }
        try {
            const response = await axios.get(`/berryAPI/conversations/unread-count`);
            setUnreadCount(response.data);
        } catch (error) {
            console.error('Error fetching unread count:', error);
        }
    };

    useEffect(() => {
        fetchUnreadCount();
        // Poll for updates every 5 seconds
        const interval = setInterval(fetchUnreadCount, 5000);
        return () => clearInterval(interval);
    }, [userId]);

    return unreadCount;
}; 