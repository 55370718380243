// project import
import dashboard from './dashboard';
import onDemandDonations from './on-demand-donations';
import routineDonations from './routine-donations';
import updateProfile from './update-profile';
// types
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [dashboard, onDemandDonations, routineDonations, updateProfile]
};

export default menuItems;
