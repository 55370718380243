import axios from 'utils/axios';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IUser } from '../types/user.interface';

type HookNotReadyResult = { ready: false };
type HookReadyResult<T extends object> = { ready: true } & T;
type HookResult<T extends object> = HookReadyResult<T> | HookNotReadyResult;

export type UseCurrentUserState = HookResult<{
    resetPassword(
        values: any
    ): void;
    updateInProgress: boolean;
    logout(): void;
    user: IUser;
}>;

export const useCurrentUser = () => {
    const [state, setState] = useState<UseCurrentUserState>({
        ready: false
    });

    // FIXME: This is a poor implementation; should separate actions as proper callbacks and handle start readiness outside of the effect and actually handle errors.
    useEffect(() => {
        (async () => {
            const response = await axios.get('/berryAPI/me');
            const logout = async () => {
                setState((s) => ({ ...s, updateInProgress: true }));
                await axios.post('/logout');
                setState({ ready: false });
                document.location.href = '/';
            };

            const resetPassword = async (
                values: any
            ) => {
                setState((s) => ({ ...s, updateInProgress: true }));
                await axios
                    .post('/berryAPI/resetPassword', values)
                    .then((updateResponse) => {
                        setState((s) => ({
                            ...s,
                            updateInProgress: false
                        }));
                    })
                    .catch((error) => {
                      setState(s => ({ ...s, updateInProgress: false }));
                      throw error;
                    });
            };

            setState({
                ready: true,
                updateInProgress: false,
                resetPassword,
                logout,
                user: response.data
            });
        })();
    }, []);

    return state;
};

// export type UseUserState = HookResult<{

//     updateUser(
//         userFields: IUser,
//         setErrors: ({}) => void,
//         setTouched: ({}) => void,
//         setStatus: ({}) => void,
//         resetForm: ({}) => void
//     ): void;
//     updateUserInProgress: boolean;
//     user: IUser;
//     regions: IRegions;
// }>;

export interface UseUserState {
    createInProgress: boolean
    createUser(data: any): void
    updateInProgress: boolean
    updateUser(data: any): void
    deleteInProgress: boolean
    deleteUser(data: any): void
    approveInProgress: boolean
    approveUser(data: any): void
    reactivateInProgress: boolean
    reactivateUser(data: any): void
    // updateUser(
    //     userFields: IUser,
    //     setErrors: ({}) => void,
    //     setTouched: ({}) => void,
    //     setStatus: ({}) => void,
    //     resetForm: ({}) => void
    // ): void;
    // updateUserInProgress: boolean;
    user: IUser;
    // regions: IRegions;
}

// export const useUser = (loggedInUser: IUser, userId?: IUserId) => {
export const useUser = () => {

    const navigate = useNavigate();
    // const usersState = useUsers();

    const [state, setState] = useState<UseUserState>({
        // ready: false,
        createInProgress: false,
        createUser: null,
        updateInProgress: false,
        updateUser: null,
        deleteInProgress: false,
        deleteUser: null,
        approveInProgress: false,
        approveUser: null,
        reactivateInProgress: false,
        reactivateUser: null,
        user: null,
        // regions: null
    });

    // useEffect(() => {
    //     (async () => {
    //         var response;
    //         // if (userId) {
    //         //     response = await axios.get(`/v2/users/${userId}`);
    //         // } else {
    //         //     response = await axios.get(`/v2/regions/`);
    //         // }

    //         const updateUser = async (
    //             userFields: IUser,
    //             setErrors: ({}) => void,
    //             setTouched: ({}) => void,
    //             setStatus: ({}) => void,
    //             resetForm: ({}) => void
    //         ) => {
    //             // debugger
    //             var url: string;
    //             if (userFields.id != null) {
    //                 url = `/v2/users/${userFields.id}`;
    //             } else {
    //                 url = `/v2/users`;
    //             }
    //             setState((s) => ({ ...s, updateUserInProgress: true }));
    //             console.log('userFields: ', userFields);
    //             await axios
    //                 .put(url, userFields)
    //                 .then((updateResponse) => {
    //                     setState((s) => ({
    //                         ...s,
    //                         updateUserInProgress: false,
    //                         user: updateResponse.data.user,
    //                         regions: updateResponse.data.regions
    //                     }));
    //                     if (userFields.id) {
    //                         setStatus('User Edited');
    //                         document.location.href = '/app/users';
    //                     } else {
    //                         setStatus('User Created');
    //                         document.location.href = '/app/users';
    //                     }

    //                     //Reset form with user info so they can now be edited if they were just created
    //                     setTimeout(() => {
    //                         resetForm({ values: updateResponse.data.user });
    //                     }, 2000);
    //                 })
    //                 .catch((error) => {
    //                     setTouched(error.response.data);
    //                     setErrors(error.response.data);
    //                     setState((s) => ({
    //                         ...s,
    //                         updateUserInProgress: false
    //                     }));
    //                 });
    //         };

    //         var user: IUser;
    //         // if (response.data.user) {
    //         //     user = response.data.user;
    //         // } else {
    //         //     user = defaultUser;
    //         //     user['year_started'] = new Date().getFullYear();
    //         //     user['region'] = loggedInUser.region;
    //         //     user['province'] = loggedInUser.province;
    //         // }
    //         setState({
    //             // ready: true,
    //             user: user,
    //             // regions: response.data.regions,
    //             updateUserInProgress: false,
    //             updateUser
    //         });
    //     })();
    // }, []);

    const createUser = useCallback(
        async (data: any) => {
          let path = '';
          if (data.role == "Food Donor") {
            path = 'donors';
          } else if (data.role == "Recipient") {
            path = 'recipients';
          } else if (data.role == "Delivery Driver") {
            path = 'delivery-drivers';
          } else if (data.role == "Admin") {
            path = 'administrators';
          }
          setState(s => ({ ...s, createInProgress: true }))
          return await axios.post('/berryAPI/user/create', data, {})
          .then((response)=>{
            if (response.status == 200) {
              navigate(`/${path}`);
            }
          })
          .catch ((error)=>{
            // Error
            console.log(error);
            setState(s => ({ ...s, createInProgress: false }));
            throw error;
            // return Promise.reject(new Error('fail'))
        })
      },
      [setState]
      )

      const updateUser = useCallback(
        async (data: any) => {
          let path = '';
          if (data.role == "Food Donor") {
            path = 'donors';
          } else if (data.role == "Recipient") {
            path = 'recipients';
          } else if (data.role == "Delivery Driver") {
            path = 'delivery-drivers';
          } else if (data.role == "Admin") {
            path = 'administrators';
          }
          setState(s => ({ ...s, updateInProgress: true }));
          return await axios.post('/berryAPI/user/edit', data, {}).then((response)=>{
            if (response.status == 200) {
              navigate(`/${path}`);
            }
          }).catch((error) => {
            // Error
            console.log(error);
            setState(s => ({ ...s, updateInProgress: false }))
            throw error;
        });
        
        },
        [setState],
      )

      const deleteUser = useCallback(
        async (id: any) => {
          setState(s => ({ ...s, deleteInProgress: true }))
          await axios.post('/berryAPI/user/delete',{}, {
            params: {id}
          })
          setState(s => ({ ...s, deleteInProgress: false }))
        },
        [setState],
      )

      const approveUser = useCallback(
        async (id: any) => {
          setState(s => ({ ...s, approveInProgress: true }))
          await axios.post('/berryAPI/user/approve',{}, {
            params: {id}
          })
          setState(s => ({ ...s, approveInProgress: false }))
        },
        [setState],
      )

      const reactivateUser = useCallback(
        async (id: any) => {
          setState(s => ({ ...s, reactivateInProgress: true }))
          await axios.post('/berryAPI/user/reactivate',{}, {
            params: {id}
          })
          setState(s => ({ ...s, reactivateInProgress: false }))
        },
        [setState],
      )

    return { ...state, createUser, updateUser, deleteUser, approveUser, reactivateUser }
};

// Users may change their active role (e.g. if a recipient user has delivery driver access)
export const useUserActiveRole = () => {
  
  const changeActiveRole = async (active_role: any) => {
    await axios.post<any>('/berryAPI/user/editActiveRole', {
      active_role
    }).catch(function (error) {
      throw error;
    });
  };

  return { changeActiveRole }
}
