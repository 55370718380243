import { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_PATH } from 'config';
import useRestartTour from 'hooks/useRestartTour.hooks';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    // Card,
    // CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    // Grid,
    // InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    // OutlinedInput,
    Paper,
    Popper,
    Stack,
    // Switch,
    Typography
} from '@mui/material';

// third-party
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import FormControlSelect from 'ui-component/extended/Form/FormControlSelect';
// import UpgradePlanCard from './UpgradePlanCard';
import useAuth from 'hooks/useAuth';
import User1 from 'assets/images/users/knead_user1.png';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { useUserActiveRole, useCurrentUser } from 'hooks/useUser.hooks';

// assets
import { IconLogout, 
    // IconSearch, 
    IconSettings, 
    IconCreditCard,
    // IconUser,
    IconQuestionMark,
    IconRefresh
} from '@tabler/icons';
import useConfig from 'hooks/useConfig';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const navigate = useNavigate();

    // const [sdm, setSdm] = useState(true);
    // const [value, setValue] = useState('');
    // const [notification, setNotification] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const { logout, user } = useAuth();
    const [open, setOpen] = useState(false);
    const {restartTour, success} = useRestartTour();

    const userState = useCurrentUser();
    const editActiveRole = useUserActiveRole();
    const dispatch = useDispatch();

    const active_role_options = [
        { label: "Delivery Driver", value: "Delivery Driver" },
        { label: "Recipient", value: "Recipient" },
    ];

    const subscriptionAndBillingUrl = 'https://billing.stripe.com/p/login/00gfZ3bgB9WvbhScMM';

    // Handle changes in the user's active role (e.g. if a recipient user has delivery driver access)
    const handleChange = useCallback(
        async (value: string) => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Setting active role, reloading.',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            await editActiveRole.changeActiveRole(value);
            window.location.href = BASE_PATH + '/dashboard';
        }, [],
    );

    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<any>(null);
    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    const openInNewTab = (url: string): void => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) {
            newWindow.opener = null;
        }
    }
    const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number, route: string = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
        if ((anchorRef.current && anchorRef.current.contains(event.target))
            || ((event.target as HTMLElement).localName === 'body')) { // Keep menu open when accessing the "active role" dropdown form
            return;
        }
        setSelectedIndex(-1); //added this to remove ListButton highlight when popup closes


        setOpen(false);
    };
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleRestartTour = async () => {
        await restartTour(user.id); // Call the hook's function

        if (success) {
            await navigate('/dashboard'); // Redirect to the dashboard on success
            user.show_guided_onboarding = true;
        }
    };
    

    if (!userState.ready) {
        return <div>Loading</div>;
    }
    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        src={User1}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                        alt="user-images"
                    />
                }
                label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
                aria-label="user-account"
            />

            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 14]
                        }
                    }
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Box sx={{ p: 2, pb: 0 }}>
                                            <Stack>
                                                <Stack direction="row" spacing={0.5} alignItems="center">
                                                    <Typography variant="h4">Welcome back,</Typography>
                                                    <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                        {`${user?.first_name} ${user?.last_name}`}
                                                    </Typography>
                                                </Stack>

                                                {userState.user?.delivery_driver_access ? (
                                                <div className="field field-active-role-selection" style={{marginTop: '1em', marginBottom: '1em'}} >
                                                    <FormControlSelect onChange={handleChange}
                                                        options={active_role_options}
                                                        captionLabel="Your Active Role"
                                                        selected={user?.role}
                                                    />
                                                </div>
                                                ) : (
                                                <Typography variant="subtitle1" sx={{mt: 1, mb: 1}}>{user?.role}</Typography>
                                                )}

                                            </Stack>
                                            <Divider />
                                        </Box>
                                        <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                                            <Box sx={{ p: 2, pt: 0 }}>
                                                <List
                                                    component="nav"
                                                    sx={{
                                                        width: '100%',
                                                        maxWidth: 350,
                                                        minWidth: 300,
                                                        backgroundColor: theme.palette.background.paper,
                                                        borderRadius: '10px',
                                                        [theme.breakpoints.down('md')]: {
                                                            minWidth: '100%'
                                                        },
                                                        '& .MuiListItemButton-root': {
                                                            mt: 0.5
                                                        }
                                                    }}
                                                >
                                                    <ListItemButton
                                                        sx={{ borderRadius: `${borderRadius}px` }}
                                                        selected={selectedIndex === 0}
                                                        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                                                            handleListItemClick(event, 0, '/user/update-password')
                                                        }
                                                    >
                                                        <ListItemIcon>
                                                            <IconSettings stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="body2">
                                                                    <FormattedMessage id="update-password" />
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                    {(user.role == "Admin") &&
                                                        <>
                                                            <ListItemButton
                                                            sx={{ borderRadius: `${borderRadius}px` }}
                                                            selected={selectedIndex === 1}
                                                            onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                                                                openInNewTab(subscriptionAndBillingUrl)
                                                            }
                                                            >
                                                            <ListItemIcon>
                                                                <IconCreditCard stroke={1.5} size="1.3rem" />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography variant="body2">
                                                                        <FormattedMessage id="Subscription & Billing" />
                                                                    </Typography>
                                                                }
                                                            />
                                                            </ListItemButton>
                                                            <ListItemButton
                                                                sx={{ borderRadius: `${borderRadius}px` }}
                                                                selected={selectedIndex === 2}
                                                                onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                                                                    handleListItemClick(event, 1, '/frequently-asked-questions')
                                                                }
                                                            >
                                                                <ListItemIcon>
                                                                    <IconQuestionMark stroke={1.5} size="1.3rem" />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography variant="body2">
                                                                            <FormattedMessage id="faq" />
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </ListItemButton>
                                                        </>
                                                    }
                                                    <ListItemButton
                                                        sx={{ borderRadius: `${borderRadius}px` }}
                                                        selected={selectedIndex === 3}
                                                        onClick={handleRestartTour}
                                                    >
                                                        <ListItemIcon>
                                                            <IconRefresh stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="body2">
                                                                    <FormattedMessage id="Restart Product Tour" />
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                    <ListItemButton
                                                        sx={{ borderRadius: `${borderRadius}px` }}
                                                        selected={selectedIndex === 4}
                                                        onClick={handleLogout}
                                                    >
                                                        <ListItemIcon>
                                                            <IconLogout stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="body2">
                                                                    <FormattedMessage id="logout" />
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                </List>
                                            </Box>
                                        </PerfectScrollbar>
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
