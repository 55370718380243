import axios from 'utils/axios';
import { useEffect, useState } from 'react'
// import { UserRoleId } from '../types/user.interface'
import { IOrganization, IOrganizationId, defaultOrganization } from 'types/organization'

type HookNotReadyResult = { ready: false }
type HookReadyResult<T extends object> = { ready: true } & T
type HookResult<T extends object> = HookReadyResult<T> | HookNotReadyResult

export type UseCurrentOrganizationState = HookResult<{
  updateMyOrganization(organizationFields: IOrganization, setErrors: ({}) => void, setTouched: ({}) => void, setStatus: ({}) => void, resetForm: ({}) => void): void
  updateOrganizationInProgress: boolean
  organization: IOrganization
}>;

export const useCurrentOrganization = () => {
    const [state, setState] = useState<UseCurrentOrganizationState>({
        ready: false
    });

    // FIXME: This is a poor implementation; should separate actions as proper callbacks and handle start readiness outside of the effect and actually handle errors.
    useEffect(() => {
        (async () => {
          
            const response = await axios.get('/berryAPI/organization');
            
            //Updates current user's organization
            const updateMyOrganization = async (organizationFields: IOrganization, setErrors: ({}) => void, setTouched: ({}) => void, setStatus: ({}) => void, resetForm: ({}) => void) => {
              
              let formData = new FormData();
              formData.append('name', organizationFields.name)
              formData.append('waiver', organizationFields.waiver)
              formData.append('weight_unit', organizationFields.weight_unit)
              formData.append('distance_unit', organizationFields.distance_unit)
              formData.append('temperature_unit', organizationFields.temperature_unit)
              formData.append('country', organizationFields.country)
              formData.append('theme_colour', organizationFields.theme_colour)
              
              // If icon not set, don't append
              if (organizationFields.logo_icon)
                formData.append('logo_icon', organizationFields.logo_icon);
              // If banner not set, don't append
              if (organizationFields.logo_banner)
                formData.append('logo_banner', organizationFields.logo_banner);

              // Email notification toggles
              formData.append('notification_signup_within_24_hours', JSON.stringify(organizationFields.notification_signup_within_24_hours));
              formData.append('notification_new_on_demand_route', JSON.stringify(organizationFields.notification_new_on_demand_route));
              formData.append('notification_cancelled_on_demand_route_to_admin', JSON.stringify(organizationFields.notification_cancelled_on_demand_route_to_admin));
              formData.append('notification_new_driver_signup', JSON.stringify(organizationFields.notification_new_driver_signup));
              formData.append('notification_cancelled_route_within_24_hours', JSON.stringify(organizationFields.notification_cancelled_route_within_24_hours));
              formData.append('notification_route_instance_comment', JSON.stringify(organizationFields.notification_route_instance_comment));
              formData.append('notification_no_show', JSON.stringify(organizationFields.notification_no_show));

              setState(s => ({ ...s, updateOrganizationInProgress: true }))

              await axios.post(
                '/berryAPI/organization',
                formData,
                {
                  // headers: { Authorization: `Bearer ${APP_INIT.apiToken}`, 'Content-Type': 'multipart/form-data' },
                },
              ).then(updateResponse => {
                setState(s => ({
                  ...s,
                  updateOrganizationInProgress: false,
                  organization: updateResponse.data.organization,
                }))
                
                if (organizationFields.id)
                {
                  setStatus("Organization Edited")
                }
                else
                {
                  setStatus("Organization Created")
                }
                
                //Reset form with organization info so they can now be edited if they were just created
                setTimeout(() => { resetForm({values: updateResponse.data}) }, 2000)
              }).catch(error => {
                setTouched(error.response.data)
                setErrors(error.response.data)
                setState(s => ({
                  ...s,
                  updateOrganizationInProgress: false,
                }))
              });
              
            }
            
            setState({
              ready: true,
              organization: response.data,
              updateOrganizationInProgress: false,
              updateMyOrganization,
            });
            
        })();
    }, []);

    return state;
};

export type UseOrganizationState = HookResult<{
  updateOrganization(organizationFields: IOrganization, setErrors: ({}) => void, setTouched: ({}) => void, setStatus: ({}) => void, resetForm: ({}) => void): void
  updateOrganizationInProgress: boolean
  organization: IOrganization
}>

export const useOrganization = (organizationID? : IOrganizationId) => {
  const [state, setState] = useState<UseOrganizationState>({
    ready: false,
  })
  
  useEffect(() => {
    (async () => {
      
      var organization:IOrganization;
      
      if (organizationID)
      {
        const response = await axios.get(`/berryAPI/superuser/organization/${organizationID}`);
        if (response.data)
        {
          organization = response.data;
        }
      }
      else
      {
        organization = defaultOrganization;
      }
      
      //Creates/Updates other organizations
      const updateOrganization = async (organizationFields: IOrganization, setErrors: ({}) => void, setTouched: ({}) => void, setStatus: ({}) => void, resetForm: ({}) => void) => {
        
        var url: string;
        
        if (organizationFields.id != null)
        {
          url = `/berryAPI/superuser/organization/${organizationFields.id}`
        }
        else
        {
          url = `/berryAPI/superuser/organization`
        }
        
        let formData = new FormData();
        formData.append('name', organizationFields.name)
        
        setState(s => ({ ...s, updateOrganizationInProgress: true }))

        await axios.post(
          url,
          formData,
          {
            // headers: { Authorization: `Bearer ${APP_INIT.apiToken}`, 'Content-Type': 'multipart/form-data' },
          },
        ).then(updateResponse => {
          setState(s => ({
            ...s,
            updateOrganizationInProgress: false,
            organization: updateResponse.data.organization,
          }))
          
          if (organizationFields.id)
          {
            setStatus("Organization Edited")
          }
          else
          {
            setStatus("Organization Created")
          }
          
          //Reset form with organization info so they can now be edited if they were just created
          setTimeout(() => { resetForm({values: updateResponse.data}) }, 2000)
        }).catch(error => {
          setTouched(error.response.data)
          setErrors(error.response.data)
          setState(s => ({
            ...s,
            updateOrganizationInProgress: false,
          }))
        });
        
      }
      
      setState({
        ready: true,
        organization: organization,
        updateOrganizationInProgress: false,
        updateOrganization,
      })
      
    })()
  }, [])

  return state
}