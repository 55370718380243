// project import
import dashboard from './dashboard';
import routesWithHH from './routesWithHH';
import routesWithoutHH from './routesWithoutHH';
import achievements from './achievements';
import prizes from './prizes';
import organization from './organization';
import donor from './donor';
import recipient from './recipient';
import deliveryDriver from './delivery-driver';
import programs from './programs';
import messages from './messages';
// types
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS ||============================== //

export const adminMenuItemsWithHH: { items: NavItemType[] } = {
    items: [dashboard, routesWithHH, donor, recipient, deliveryDriver, messages, programs, achievements, prizes, organization]
};

export const adminMenuItemsWithoutHH: { items: NavItemType[] } = {
    items: [dashboard, routesWithoutHH, donor, recipient, deliveryDriver, messages, programs, achievements, prizes, organization]
};