import React, { createContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

// third-party
import { Chance } from 'chance';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

// types
import { InitialLoginContextProps, JWTContextType } from 'types/auth';

const chance = new Chance();

// constant
const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const setSession = (serviceToken?: string | null, sessionID?: string | null) => {
    axios.defaults.params = {};
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        localStorage.setItem('session', sessionID);
        axios.defaults.params['session'] = sessionID;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
        localStorage.removeItem('session');
        delete axios.defaults.params['session'];
    }
};

// Users can save their views for the following datagrids
const dataGridStates = [
    "achievementsDataGridState",
    "activitiesDataGridState",
    "adminDeliveryDriversUserInstancesDataGridState",
    "adminDeliveryDriversUserDataGridState",
    "donorApplicationsDataGridState",
    "adminDonorsUserDataGridState",
    "adminAdminsUserDataGridState",
    "foodProductsDataGridState",
    "programsDataGridState",
    "recipientApplicationsDataGridState",
    "adminRecipientsUserDataGridState",
    "regionsDataGridState",
    "routeInstancesByDateDataGridState",
    "adminRouteInstancesDataGridState",
    "adminRoutesDataGridState",
    "deliveryDriverRouteInstancesDataGridState",
    "donorRouteInstancesDataGridState",
    "recipientRouteInstancesDataGridState",
    "organizationsDataGridState",
];

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    
    const navigate = useNavigate();

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                const session = window.localStorage.getItem('session');
                if (serviceToken) {
                    setSession(serviceToken, session);
                    const response = await axios.get('/berryAPI/me');
                    const user = response.data;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (email: string, password: string) => {
        const response = await axios.post('/berryAPI/login', { email, password });
        const serviceToken = response.data.token;
        const session = response.data.session;
        const user = response.data.user;
        setSession(serviceToken, session);
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user
            }
        });

        const savedDatagridViews = JSON.parse(user.saved_datagrid_views);

        dataGridStates.forEach((dataGridState : string) => {
            if (savedDatagridViews[dataGridState]) {
                localStorage.setItem(dataGridState, savedDatagridViews[dataGridState]);
            }
        });
    };
    
    const registerOrganization = async (
      organizationName: string,
      firstName: string,
      lastName: string,
      regionName: string,
      programName: string,
      phone: string,
      email: string,
      password: string,
      password_confirmation: string,
  ) => {
      // todo: this flow need to be recode as it not verified
      const id = chance.bb_pin();
      const response = await axios
          .post('/berryAPI/registerOrganization', {
              organizationName,
              firstName,
              lastName,
              regionName,
              programName,
              phone,
              email,
              password,
              password_confirmation,
          })
          .catch(function (error) {
              throw error;
          });
      if (response) { //this line causes it to redirect asap and skip all the code below. works but maybe not the best?
          return;
      }

      let users = response.data;

      if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
          const localUsers = window.localStorage.getItem('users');
          users = [
              ...JSON.parse(localUsers!),
              {
                  id,
                  email,
                  password,
                  name: `${firstName} ${lastName}`
              }
          ];
      }
      window.localStorage.setItem('users', JSON.stringify(users));
    };

    const register = async (
        role: string,
        firstName: string,
        lastName: string,
        businessName: string,
        address: string,
        city: string,
        province: string,
        postalCode: string,
        country: string,
        neighbourhood: string,
        regionID: string,
        programID: string,
        phone: string,
        donationList: string,
        donationAvailability: string,
        email: string,
        password: string,
        password_confirmation: string,
        how_did_you_hear_about_organization: string,
        name_of_group_or_company: string,
        parent_name_signed_waiver: string,
        selectedShifts: string,
        checkedResearchSurveyConsent: boolean,
        foodCategories: number[],  
        timeAvailabilities: any[]  
    ) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = await axios
            .post('/berryAPI/register', {
                role,
                firstName,
                lastName,
                businessName,
                address,
                city,
                province,
                postalCode,
                country,
                neighbourhood,
                regionID,
                programID,
                phone,
                donationList,
                donationAvailability,
                email,
                password,
                password_confirmation,
                how_did_you_hear_about_organization,
                name_of_group_or_company,
                parent_name_signed_waiver,
                selectedShifts,
                checkedResearchSurveyConsent,
                foodCategories,
                timeAvailabilities,
            })
            .catch(function (error) {
                throw error;
            });
        if (response) { //this line causes it to redirect asap and skip all the code below. works but maybe not the best?
            return;
        }

        let users = response.data;

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers!),
                {
                    id,
                    email,
                    password,
                    name: `${firstName} ${lastName}`
                }
            ];
        }
        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = async () => {
        await axios.post('/berryAPI/logout');
        setSession(null, null);
        navigate('/login');
        dispatch({ type: LOGOUT });
    };

    const forgotPassword = async (email: string) => {
        await axios.post('/berryAPI/forgotPassword', { email });
    };

    const resetPassword = async (email: string, token: string, password: string, password_confirmation: string) => {
        await axios.post('/password/reset', { email, token, password, password_confirmation });
    };

    const updateProfile = () => {};
    
    const deleteAccount = async (organization: string, email: string) => {
        await axios.post('/berryAPI/deleteAccount', { organization, email });
    };

    // Save Datagrid configurations on existing sessions
    const saveDatagridStates = async () => {
        if (axios.defaults.params && 'session' in axios.defaults.params) {
            var userDataGridStates : any = {};
            dataGridStates.map((dataGridState : string) => {
                userDataGridStates[dataGridState] = localStorage.getItem(dataGridState);
            });

            await axios.post('/berryAPI/saveDatagridStates', { userDataGridStates: userDataGridStates });
        }
    };

    // Save Datagrid configurations on each render
    saveDatagridStates();

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, registerOrganization, forgotPassword, resetPassword, updateProfile, deleteAccount }}>
            {children}
        </JWTContext.Provider>
    );
};

export default JWTContext;
