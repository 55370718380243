// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconTruckDelivery } from '@tabler/icons';

// type
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const icons = {
    IconTruckDelivery
};
const deliveryDriver: NavItemType = {
    id: 'delivery-driver',
    title: <FormattedMessage id="Delivery Driver" />,
    icon: icons.IconTruckDelivery,
    type: 'group',
    url: '/delivery-drivers'
};

export default deliveryDriver;
