import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// maintenance routing
const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const ErrorRoutes = {
    path: '*',
    element: <MinimalLayout />,
    children: [
        {
            path: '*',
            element: <MaintenanceError />
        }
    ]
};

export default ErrorRoutes;
