import { useEffect, useState, useCallback, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGridPro,
  GridColDef, 
  GridToolbar,
  GridInitialState,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { Box, 
  Button,
  CircularProgress,
} from '@mui/material';
import ConfirmationDialog from 'ui-component/ConfirmationDialog';
import { useTheme } from '@mui/material/styles';
import { IUser } from './../../../../../types/user.interface';
import { useUsers } from '../../../../../hooks/useUsers.hooks';
import { useCurrentOrganization } from './../../../../../hooks/useOrganization.hooks'
import { useUser } from '../../../../../hooks/useUser.hooks';

// export default function UsersDataGrid({setUser} : {setUser: React.Dispatch<React.SetStateAction<IUser>>}) {
export default function UsersDataGrid(props: any) {
  const { role, active } = props;
  const theme = useTheme();
  const usersState = useUsers(role, active);
  const userState = useUser();
  const organizationState = useCurrentOrganization();
  const navigate = useNavigate();

  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false);
  const [showReactivateConfirmation, setShowReactivateConfirmation] = useState(false);
  const [activeUserID, setActiveUserID] = useState(-1);

  const apiRef = useGridApiRef();
  const [initialState, setInitialState] = useState<GridInitialState>();

  const saveSnapshot = useCallback(() => {
    if (apiRef?.current?.exportState && localStorage) {
      const currentState = apiRef.current.exportState();
      localStorage.setItem('adminAdminsUserDataGridState', JSON.stringify(currentState));
    }
  }, [apiRef]);

  useLayoutEffect(() => {
    const stateFromLocalStorage = localStorage?.getItem('adminAdminsUserDataGridState');
    setInitialState(stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {});

    // handle refresh and navigating away/refreshing
    window.addEventListener('beforeunload', saveSnapshot);

    return () => {
      // in case of an SPA remove the event-listener
      window.removeEventListener('beforeunload', saveSnapshot);
      saveSnapshot();
    };
  }, [saveSnapshot]);

  const columns: GridColDef[] = [
    { field: 'region_name', headerName: 'Region', width: 100,
    valueGetter: (value, row) =>
      {
        return `${row.region.name}`},
    },
    { field: 'first_name', headerName: 'First Name', width: 130 },
    { field: 'last_name', headerName: 'Last name', width: 130 },
    { field: 'email', headerName: 'Email', width: 300 },
    ...(active ? [{
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      width: 90,
      renderCell: (params: { row: any; }) => {
        const onClickEdit = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking
          navigate('/administrator/edit', {state: {route: params.row}});
        };
  
        return <Button onClick={onClickEdit}>Edit</Button>;
      },
    }] : []),
    ...(active ? [{
      field: 'archive',
      headerName: 'Archive',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 90,
      renderCell: (params: { row: any; }) => {
        const onClickArchive = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking
          setActiveUserID(params.row.id as number);
          setShowArchiveConfirmation(true);
        };
  
        return <Button onClick={onClickArchive}>Archive</Button>;
      },
    }] : []),
    ...(!active ? [{
      field: 'reactivate',
      headerName: 'Reactivate',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 100,
      renderCell: (params: {  row: any;  }) => {
        const onClickReactivate = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking
          setActiveUserID(params.row.id as number);
          setShowReactivateConfirmation(true);
        };
  
        return <Button onClick={onClickReactivate}>Reactivate</Button>;
      },
    }] : []),
    { field: 'id', headerName: 'User ID', width: 70 },
  ];

  const [rows, setRows] = useState<IUser[]>([]);
  let usersArray : IUser[];
  useEffect(() => {
    if (usersState?.ready && organizationState.ready && !userState.deleteInProgress) {
      usersArray = Object.values(usersState.users).sort((a : any, b : any) => a.first_name.localeCompare(b.first_name));
      setRows(usersArray);
    }
  }, [usersState?.ready, organizationState.ready, userState?.deleteInProgress, usersState.users]);
  
  const onRowsSelectionHandler = (ids: any) => {
    // const selectedRowsData = ids.map((id: any) => rows.find((row) => row.id === id));
    // setUser(selectedRowsData[0]);
  };

  const cancelDialog = () => {
    setShowArchiveConfirmation(false);
    setShowReactivateConfirmation(false);
  }

  const confirmArchive = async () => {
    await userState.deleteUser(activeUserID);
    await usersState.fetch(); // to refresh users
    setShowArchiveConfirmation(false);
  };

  const confirmReactivate = async () => {
    await userState.reactivateUser(activeUserID);
    await usersState.fetch(); // to refresh users
    setShowReactivateConfirmation(false);
  };

  if (!usersState?.ready) {
    return (
      <div>Loading...</div>
  )
  }

  // Handle saved table views
  if (!initialState) {
    return <CircularProgress />;
  }
  
  return (
    <div style={{ height: 700, width: '100%' }}>
      <Box
        sx={{
        height: 700,
        width: '100%',
        '& .MuiDataGrid-root': {
            border: 'none',
            '& .MuiDataGrid-cell': {
                borderColor: theme.palette.mode === 'dark' ? theme.palette.text.primary + 15 : 'grey.200'
            },
            '& .MuiDataGrid-columnsContainer': {
                color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900',
                borderColor: theme.palette.mode === 'dark' ? theme.palette.text.primary + 15 : 'grey.200'
            },
            '& .MuiDataGrid-columnSeparator': {
                color: theme.palette.mode === 'dark' ? theme.palette.text.primary + 15 : 'grey.200'
            }
          },
          '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            borderRight: `1px solid ${
              theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
            }`,
          },
      }}
      >
      <DataGridPro
      rows={rows} 
      columns={columns}
      pagination
      slots={{ toolbar: GridToolbar }}
      onRowSelectionModelChange={(ids : any) => onRowsSelectionHandler(ids)}
      apiRef={apiRef}
      initialState={{
        ...initialState,
      }}
      />
      <ConfirmationDialog
          open={showArchiveConfirmation}
          onCancel={cancelDialog}
          onConfirm={confirmArchive}
          title="Archive User?"
          message="Confirm you want to archive this user?"
      />
      <ConfirmationDialog
          open={showReactivateConfirmation}
          onCancel={cancelDialog}
          onConfirm={confirmReactivate}
          title="Reactivate User?"
          message="Confirm you want to reactivate this user?"
      />
      </Box>
    </div>
  );
}