import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import {useState} from 'react';

export interface ConfirmationDialogProps {
	title: string;
	message: string;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmationDialog = ({title, message, open, onCancel, onConfirm}: ConfirmationDialogProps) => {

	const [error, setError] = useState("");

  const handleCancel = () => {
    onCancel();
		setError("");
  };

  const handleConfirm = async () => {
		try
		{
			setError("");
    	await onConfirm();
		}
		catch (err: any)
		{
			if (err.Error)
			{
				setError(err.Error);
			}
			else if (err.message)
			{
				setError(err.message);
			}
		}
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
      {message}
			{error != "" &&
				<Alert style={{marginTop: "10px"}} severity="error">{error}</Alert>
			}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;