// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconAdjustmentsHorizontal,IconFileAnalytics } from '@tabler/icons';

// type
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const icons = {
    IconAdjustmentsHorizontal,
    IconFileAnalytics
};
const organization: NavItemType = {
  id: 'organizationSection',
  title: <FormattedMessage id="Organization" />,
  type: 'group',
  children: [
    {
        id: 'reports',
        title: <FormattedMessage id="Reports" />,
        type: 'item',
        url: '/report/route',
        target: false,
        icon: icons.IconFileAnalytics
    }
          ]
};

export default organization
