import { useUsers } from 'hooks/useUsers.hooks'
import UsersDataGrid from '../view-users/UsersDataGrid'
import { Button, Grid } from '@mui/material'
import { Link } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';


const AdminArchivedAdministratorScreen = function() {
  const usersState = useUsers()
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
      <Button variant="contained" component={Link} to='/administrators'>
          Return to Active Administrators
      </Button>
      </Grid>
      
      <Grid item xs={12}>
      <MainCard title="Archived Administrators">
      {!usersState.ready ? (
        <div>Loading</div>
      ) : (
        <UsersDataGrid
          role="Admin"
          active={false}
        />
      )}
      </MainCard>
      </Grid>
    </Grid>
  )
}

export default AdminArchivedAdministratorScreen
