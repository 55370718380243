// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBasket } from '@tabler/icons';

// type
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const icons = {
    IconBasket
};
const donor: NavItemType = {
    id: 'donor',
    title: <FormattedMessage id="Donor" />,
    icon: icons.IconBasket,
    type: 'group',
    url: '/donors'
};

export default donor;
