import { useEffect, useState, useCallback, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGridPro,
  GridColDef, 
  GridToolbar,
  GridInitialState,
  useGridApiRef,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import {
  Box,
  Button,
  Chip,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import ConfirmationDialog from 'ui-component/ConfirmationDialog';
import { useTheme } from '@mui/material/styles';
import { IUser } from './../../../../types/user.interface';
import { ITag } from 'types/tag';
import { useUsers } from '../../../../hooks/useUsers.hooks';
import { useCurrentOrganization } from './../../../../hooks/useOrganization.hooks'
import { useUser } from '../../../../hooks/useUser.hooks';

export default function UsersDataGrid(props: any) {
  const {setUser, role, active} = props;
  const theme = useTheme();
  const usersState = useUsers(role, active);
  const userState = useUser();
  const organizationState = useCurrentOrganization();
  const navigate = useNavigate();

  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false);
  const [showReactivateConfirmation, setShowReactivateConfirmation] = useState(false);
  const [activeUserID, setActiveUserID] = useState(-1);

  const apiRef = useGridApiRef();
  const [initialState, setInitialState] = useState<GridInitialState>();

  const saveSnapshot = useCallback(() => {
    if (apiRef?.current?.exportState && localStorage) {
      const currentState = apiRef.current.exportState();
      localStorage.setItem('adminDeliveryDriversUserDataGridState', JSON.stringify(currentState));
    }
  }, [apiRef]);

  useLayoutEffect(() => {
    const stateFromLocalStorage = localStorage?.getItem('adminDeliveryDriversUserDataGridState');
    setInitialState(stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {});

    // handle refresh and navigating away/refreshing
    window.addEventListener('beforeunload', saveSnapshot);

    return () => {
      // in case of an SPA remove the event-listener
      window.removeEventListener('beforeunload', saveSnapshot);
      saveSnapshot();
    };
  }, [saveSnapshot]);

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      // Show 'neighbourhood' column if the admin is part of Fresh Routes
      neighbourhood: (organizationState.ready && organizationState?.organization.id == 3) ? true : false,
    });

  const columns: GridColDef[] = [
    { field: 'region_name', headerName: 'Region', width: 100,
    valueGetter: (value, row) =>
      {
        return `${row.region.name}`},
    },
    { field: 'first_name', headerName: 'First Name', width: 130 },
    { field: 'last_name', headerName: 'Last name', width: 130 },
    { field: 'email', headerName: 'Email', width: 300 },
    { field: 'phone', headerName: 'Phone Number', width: 200 },
    { field: 'program_name', headerName: 'Program', width: 130 },
    { field: 'profile_tags',
      headerName: 'Profile Tags',
      width: 300,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.profile_tags.join(", ")} >
          <Box component="div" sx={{ display: 'flex', gap: 0.5 }} >
            {params.row.profile_tags.map((tag : string)=>(
              <Chip style={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light }}
                label={tag}
              />
            ))}
          </Box>
          </Tooltip>
        )
      },
    },
    { field: 'neighbourhood', headerName: 'Neighbourhood', width: 130 },
    ...(active ? [{
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      width: 90,
      renderCell: (params: { row: any; }) => {
        const onClickEdit = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking
          navigate('/delivery-driver/edit', {state: {route: params.row}});
        };
  
        return <Button onClick={onClickEdit}>Edit</Button>;
      },
    }] : []),
    ...(active ? [{
      field: 'archive',
      headerName: 'Archive',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 90,
      renderCell: (params: { row: any; }) => {
        const onClickArchive = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking
          setActiveUserID(params.row.id as number);
          setShowArchiveConfirmation(true);
        };
  
        return <Button onClick={onClickArchive}>Archive</Button>;
      },
    }] : []),
    ...(!active ? [{
      field: 'reactivate',
      headerName: 'Reactivate',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 100,
      renderCell: (params: {  row: any;  }) => {
        const onClickReactivate = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking
          setActiveUserID(params.row.id as number);
          setShowReactivateConfirmation(true);
        };
  
        return <Button onClick={onClickReactivate}>Reactivate</Button>;
      },
    }] : []),
    { field: 'id', headerName: 'User ID', width: 70 },
  ];

  function getProgramName(program_id: number, programs: any) {
    let programObj = programs.find((program: any) => program.id === program_id);
    return programObj ? programObj.name : "Not applicable";
  }

  const [rows, setRows] = useState<IUser[]>([]);
  let usersArray : IUser[];
  useEffect(() => {
    if (usersState?.ready && organizationState.ready && !userState.deleteInProgress) {
      usersArray = Object.values(usersState.users).sort((a : any, b : any) => a.first_name.localeCompare(b.first_name));
      usersArray = usersArray.map((user : any)=>{
        user.program_name = getProgramName(user.program_id, organizationState.organization.programs);
        user.profile_tags = user.tags?.map((tag:ITag) => tag.name) || [];
        return user;
    })
      setRows(usersArray);
    }
  }, [usersState?.ready, organizationState.ready, userState?.deleteInProgress, usersState.users]);
  
  const onRowsSelectionHandler = (ids: any) => {
    const selectedRowsData = ids.map((id: any) => rows.find((row) => row.id === id));
    setUser(selectedRowsData[0]);
  };

  const cancelDialog = () => {
    setShowArchiveConfirmation(false);
    setShowReactivateConfirmation(false);
  }

  const confirmArchive = async () => {
    await userState.deleteUser(activeUserID);
    await usersState.fetch(); // to refresh users
    setShowArchiveConfirmation(false);
  };

  const confirmReactivate = async () => {
    await userState.reactivateUser(activeUserID);
    await usersState.fetch(); // to refresh users
    setShowReactivateConfirmation(false);
  };

  if (!usersState?.ready) {
    return (
      <div>Loading...</div>
  )
  }

  // Handle saved table views
  if (!initialState) {
    return <CircularProgress />;
  }

  return (
    <div style={{ height: 700, width: '100%' }}>
      <Box
        sx={{
        height: 700,
        width: '100%',
        '& .MuiDataGrid-root': {
            border: 'none',
            '& .MuiDataGrid-cell': {
                borderColor: theme.palette.mode === 'dark' ? theme.palette.text.primary + 15 : 'grey.200'
            },
            '& .MuiDataGrid-columnsContainer': {
                color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900',
                borderColor: theme.palette.mode === 'dark' ? theme.palette.text.primary + 15 : 'grey.200'
            },
            '& .MuiDataGrid-columnSeparator': {
                color: theme.palette.mode === 'dark' ? theme.palette.text.primary + 15 : 'grey.200'
            }
          },
          '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            borderRight: `1px solid ${
              theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
            }`,
          },
      }}
      >
      <DataGridPro 
      rows={rows} 
      columns={columns}
      pagination
      slots={{ toolbar: GridToolbar }}
      onRowSelectionModelChange={(ids : any) => onRowsSelectionHandler(ids)}
      apiRef={apiRef}
      initialState={{
        ...initialState,
      }}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={(newModel) =>
        setColumnVisibilityModel(newModel)
      }
      />
      <ConfirmationDialog
          open={showArchiveConfirmation}
          onCancel={cancelDialog}
          onConfirm={confirmArchive}
          title="Archive User?"
          message="Confirm you want to archive this user?"
      />
      <ConfirmationDialog
          open={showReactivateConfirmation}
          onCancel={cancelDialog}
          onConfirm={confirmReactivate}
          title="Reactivate User?"
          message="Confirm you want to reactivate this user?"
      />
      </Box>
    </div>
  );
}