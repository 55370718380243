import axios from 'utils/axios';
import { useState } from 'react';

const useRestartTour = () => {
    
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);

    const restartTour = async (userId: string) => {
        setSuccess(false);
        setError(null);

        try { 
            const response = await axios.put(`berryAPI/user/${userId}/toggle-onboarding`, {
                method: 'PUT',
                headers: {'Content-Type': 'application/json',},
                show_guided_onboarding: true,
            });

            if (!(response.status = 200)){
                throw new Error('Failed to restart guided onboarding tour');
            }
            setSuccess(true);
        } catch (err){
            setError(err instanceof Error ? err.message: 'An unknown error occured');
            console.error(err);
        }
    }


    const endTour = async (userId: string) => {
        setSuccess(false);
        setError(null);

        try {
            const response = await axios.put(`berryAPI/user/${userId}/toggle-onboarding`, {
                method: 'PUT',
                headers: {'Content-Type': 'application/json',},
                show_guided_onboarding: false,
            });

            if (!(response.status = 200)){
                throw new Error('Failed to end guided onboarding tour');
            }
            setSuccess(true);
        } catch (err){
            setError(err instanceof Error ? err.message: 'An unknown error occured');
            console.error(err);
        }
    }

    return {restartTour, endTour, error, success};
};

export default useRestartTour;