// material-ui
import { Button, Grid } from '@mui/material';

//third party
import { Formik, Form, FormikHelpers } from 'formik';
import MainCard from 'ui-component/cards/MainCard';


import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'utils/axios';
import { useState } from 'react';

// ==============================|| ROUTE REPORT PAGE ||============================== //

export interface ReportFormValues {
  startDate: Date,
  endDate: Date,
}

const RouteReportPage = () => {

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async(values: ReportFormValues, actions: FormikHelpers<ReportFormValues>) => {
    let startDateFormatted = new Date(values.startDate.setHours(0,0,0,0));
    let endDateFormatted = new Date(values.endDate.setHours(23,59,59,999));

    let data: any = {startTimestamp: startDateFormatted.getTime()/1000, endTimestamp: endDateFormatted.getTime()/1000};
    console.log('SUBMITTED VALUES', data);
    
    let response = await axios.post(`/berryAPI/donor/generate-route-report`, data, {responseType: 'blob' });
    var dataURL = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = dataURL;
    link.setAttribute(
      'download',
      `RouteReport.csv`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);

    if (response.status == 200) {
      setIsSubmitted(true);
    }

  }

  const handleRestart = () => {
    setIsSubmitted(false);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
     {!isSubmitted ? <MainCard title="Generate a Route Report">
       
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                startDate: new Date(),
                endDate: new Date()
              }}
              onSubmit={handleSubmit}
            >
              {({ resetForm, setFieldValue, handleChange, handleBlur, submitForm, isSubmitting, values }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        label="Start Date"
                        value={values.startDate}
                        onChange={(newValue: Date | null) => setFieldValue('startDate', newValue)}
                        slotProps={{ textField: { fullWidth: true } }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        label="End Date"
                        value={values.endDate}
                        onChange={(newValue: Date | null) => setFieldValue('endDate', newValue)}
                        slotProps={{ textField: { fullWidth: true } }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Generate
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MainCard> : 
      <Grid item xs={12}>
        <MainCard>
          <MainCard title="Route Report Generated" children="Your route report has been generated and will now be downloaded by your browser. If it does not download please ensure you have allowed pop-ups on this site."></MainCard>
          <Button onClick={handleRestart} variant="contained" color="primary">Restart</Button>
        </MainCard>
      </Grid>
      }
    </LocalizationProvider>
  );
}

export default RouteReportPage;
