// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconTrophy } from '@tabler/icons';

// type
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const icons = {
    IconTrophy
};

const prizes: NavItemType = {
    id: 'prizes-section',
    title: <FormattedMessage id="Prizes" />,
    // caption: <FormattedMessage id="routes-caption" />,
    icon: icons.IconTrophy,
    type: 'group',
    children: [
        {
            id: 'prizes',
            title: <FormattedMessage id="Prizes" />,
            type: 'collapse',
            icon: icons.IconTrophy,
            children: [
                {
                    id: 'prize-item',
                    title: <FormattedMessage id="Prizes" />,
                    type: 'item',
                    url: '/prizes',
                    target: false
                },
                {
                    id: 'prize-queue',
                    title: <FormattedMessage id="Prize Queue" />,
                    type: 'item',
                    url: '/prize-queue',
                    target: false
                }
            ]
        }
    ]
};

export default prizes;
