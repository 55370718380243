// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUserCircle } from '@tabler/icons';

// type
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const icons = {
    IconUserCircle
};
const updateProfile: NavItemType = {
    id: 'update-profile',
    title: <FormattedMessage id="Update Profile" />,
    icon: icons.IconUserCircle,
    type: 'group',
    url: '/user/update-profile'
};

export default updateProfile;
