// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconRoute } from '@tabler/icons';

// type
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const icons = {
    IconRoute
};
const onDemandDonations: NavItemType = {
    id: 'on-demand-routes-section',
    title: <FormattedMessage id="On-Demand Donations" />,
    icon: icons.IconRoute,
    type: 'group',
    children: [
        {
            id: 'routes',
            title: <FormattedMessage id="On-Demand Donations" />,
            type: 'collapse',
            icon: icons.IconRoute,
            children: [
                {
                    id: 'upcoming-on-demand-donations',
                    title: <FormattedMessage id="Upcoming On-Demand Donations" />,
                    type: 'item',
                    url: '/upcoming-on-demand-donations',
                    target: false
                },
								{
                    id: 'past-on-demand-donations',
                    title: <FormattedMessage id="Past On-Demand Donations" />,
                    type: 'item',
                    url: '/past-on-demand-donations',
                    target: false
                }
            ]
        }
    ]
};

export default onDemandDonations;
