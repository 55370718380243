import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// main routing
const Dashboard = Loadable(lazy(() => import('views/delivery-driver/dashboard')));
const DeliveryDriverRoutesPage = Loadable(lazy(() => import('views/delivery-driver/routes-section/DeliveryDriverRoutesPage')));

//update password
const UpdatePasswordPage = Loadable(lazy(() => import('views/shared/update-password')));

// ==============================|| MAIN ROUTING ||============================== //

const DeliveryDriverRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/my-upcoming-routes',
            element: <DeliveryDriverRoutesPage upcoming={true} available={false} key="upcoming" />,
        },
        {
            path: '/my-past-routes',
            element: <DeliveryDriverRoutesPage upcoming={false} available={false} key="past" />,
        },
        {
            path: '/available-routes',
            element: <DeliveryDriverRoutesPage upcoming={true} available={true} key="available" />,
        },
        {
            path: '/user/update-password',
            element: <UpdatePasswordPage />
        },
    ]
};

export default DeliveryDriverRoutes;
