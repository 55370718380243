// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconAdjustmentsHorizontal,IconFileAnalytics } from '@tabler/icons';

// type
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const icons = {
    IconAdjustmentsHorizontal,
    IconFileAnalytics
};
const organizationWithFoodProduct: NavItemType = {
  id: 'organizationSection',
  title: <FormattedMessage id="Organization" />,
  type: 'group',
  children: [
    {
        id: 'reports',
        title: <FormattedMessage id="Reports" />,
        type: 'collapse',
        icon: icons.IconFileAnalytics,
        children: [
            {
                id: 'report-route',
                title: <FormattedMessage id="Route report" />,
                type: 'item',
                url: '/report/route',
                target: false
            },
            {
                id: 'report-profile',
                title: <FormattedMessage id="Profile report" />,
                type: 'item',
                url: '/report/profile',
                target: false
            }
        ]
    },
    {
        id: 'organization-section',
        title: <FormattedMessage id="Organization" />,
        type: 'collapse',
        icon: icons.IconAdjustmentsHorizontal,
        children: [
            {
                id: 'organization',
                title: <FormattedMessage id="Organization Settings" />,
                type: 'item',
                url: '/organization',
                target: false
            },
            {
                id: 'administrators',
                title: <FormattedMessage id="Administrators" />,
                type: 'item',
                url: '/administrators',
                target: false
            },
            {
                id: 'regions',
                title: <FormattedMessage id="Regions" />,
                type: 'item',
                url: '/regions',
                target: false
            },
            {
                id: 'food-products',
                title: <FormattedMessage id="Food Products" />,
                type: 'item',
                url: '/food-products',
                target: false
            },
        ]
    }
          ]
};

export default organizationWithFoodProduct;
