import { useCurrentOrganization } from "hooks/useOrganization.hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

/*
    This guard is used to prevent access to the messaging page if messaging is not enabled for the organization.
    It will redirect to the error page if messaging is not enabled.
*/
const MessagingGuard = ({ children }: { children: React.ReactNode }) => {
    const state = useCurrentOrganization();
    const navigate = useNavigate();

    useEffect(() => {
        if (state.ready && !state.organization.messaging_enabled) {
            navigate('/pages/error', { replace: true });
        }
    }, [state.ready, navigate]);

    if (!state.ready) {
        return null;
    }

    return <>{children}</>;
};

export default MessagingGuard;