// project import
import dashboard from './dashboard';
import onDemandDonations from './on-demand-donations';
import routineDonations from './routine-donations';
import organization from './organization';

// types
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [dashboard, onDemandDonations, routineDonations, organization]
};

export default menuItems;
