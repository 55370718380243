import { IRegion } from './region.interface';

export type IOrganizationCreatedAt = string;
export type IOrganizationId = null | number;
export type IOrganizationName = string;
export type IOrganizationWeightUnit = string;
export type IOrganizationDistanceUnit = string;
export type IOrganizationTemperatureUnit = string;
export type ICountry = string;
export type IOrganizationUpdatedAt = string;
export type IOrganizationLogoBanner = string;
export type IOrganizationLogoIcon = string;
export type IOrganizationColour = string;
export type IOrganizationRegionCount = null | number;
export type IOrganizationUserCount = null | number;
export type IOrganizationWaiver = string;
export type IOrganizationPrograms = {
    id: number;
    organization_id: number;
    name: string;
    hidden_donor: boolean;
    hidden_recipient: boolean;
}[];
export type INotificationSignupWithin24Hours = boolean;
export type INotificationNewOnDemandRoute = boolean;
export type INotificationCancelledOnDemandRouteToAdmin = boolean;
export type INotificationNewDriverSignup = boolean;
export type INotificationCancelledRouteWithin24Hours = boolean;
export type INotificationRouteInstanceComment = boolean;
export type INotificationNoShow = boolean;
export type IMessagingEnabled = boolean;
export interface IOrganization {
    // created_at: IOrganizationCreatedAt
    id: IOrganizationId;
    name: IOrganizationName;
    weight_unit: IOrganizationWeightUnit;
    distance_unit: IOrganizationDistanceUnit;
    temperature_unit: IOrganizationTemperatureUnit;
    country: ICountry;
    logo_banner: IOrganizationLogoBanner;
    logo_icon: IOrganizationLogoIcon;
    theme_colour: IOrganizationColour;
    regioncount: IOrganizationRegionCount;
    usercount: IOrganizationUserCount;
    // updated_at: IOrganizationUpdatedAt
    waiver: IOrganizationWaiver;
    programs: IOrganizationPrograms;
    regions: IRegion[];
    notification_signup_within_24_hours: INotificationSignupWithin24Hours;
    notification_new_on_demand_route: INotificationNewOnDemandRoute;
    notification_cancelled_on_demand_route_to_admin: INotificationCancelledOnDemandRouteToAdmin;
    notification_new_driver_signup: INotificationNewDriverSignup;
    notification_cancelled_route_within_24_hours: INotificationCancelledRouteWithin24Hours;
    notification_route_instance_comment: INotificationRouteInstanceComment;
    notification_no_show: INotificationNoShow;
    messaging_enabled: IMessagingEnabled;
}

export var defaultOrganization: IOrganization = {
    id: null,
    name: '',
    weight_unit: 'lb',
    distance_unit: 'km',
    temperature_unit: 'c',
    country: 'Canada',
    logo_banner: '',
    logo_icon: '',
    theme_colour: '',
    regioncount: 0,
    usercount: 0,
    waiver: '',
    programs: [],
    regions: [],
    notification_signup_within_24_hours: false,
    notification_new_on_demand_route: false,
    notification_cancelled_on_demand_route_to_admin: false,
    notification_new_driver_signup: false,
    notification_cancelled_route_within_24_hours: false,
    notification_route_instance_comment: false,
    notification_no_show: false,
    messaging_enabled: false,
};
