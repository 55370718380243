// material-ui
import { DialogContent, Button, DialogTitle, Dialog, Grid, Stack, Typography, TextField, DialogActions, Select, MenuItem, FormControl, InputLabel, Checkbox, ListItemText, IconButton } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { useState, useEffect } from 'react';
import axios from 'utils/axios';
import { useCurrentUser } from 'hooks/useUser.hooks';
import { useFoodCategories } from 'hooks/useFoodCategories.hooks';

// ==============================|| UPDATE PROFILE PAGE ||============================== //

interface TimeAvailability {
    id?: number;
    weekday: string;
    start_time: Date | null;
    end_time: Date | null;
}

const UpdateProfilePage = () => {
    const userState = useCurrentUser();
    const [openFoodCategoryDialog, setOpenFoodCategoryDialog] = useState(false);
    const [openAvailabilityDialog, setOpenAvailabilityDialog] = useState(false);
    const [foodCategoryPreference, setFoodCategoryPreference] = useState<number[]>([]);
    const [availabilityPreference, setAvailabilityPreference] = useState<TimeAvailability[]>([]);
    const [availabilityNotes, setAvailabilityNotes] = useState<string>('');
    const [userProfile, setUserProfile] = useState<any>(null);
    const [foodCategoryNotes, setFoodCategoryNotes] = useState<string>('');
    const { foodCategories } = useFoodCategories();
    const dayOfTheWeekOptions = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
   
    // Fetch user profile data
    useEffect(() => {
        const fetchProfile = async () => {
            if (userState.ready) {
                const response = await axios.get('/berryAPI/recipient/profile');
                setUserProfile(response.data);
            }
        };
        
        fetchProfile();
    }, [userState.ready]);

    if (!userProfile) {
        return <div>Loading</div>;
    }

    /**
     * Handles the update of user preferences.
     * Closes the dialogs and updates the user state with the new preferences.
     * Converts the availability preference to the correct format for the backend.
     * Fetches fresh data after update.
     */
    const handleUpdatePreference = async () => {
        const formattedAvailabilities = availabilityPreference.map(time => ({   
            weekday: time.weekday,
            start_time: time.start_time ? format(time.start_time, 'HH:mm:ss') : null,
            end_time: time.end_time ? format(time.end_time, 'HH:mm:ss') : null,
            id: time.id
        }));

        await axios.post('/berryAPI/recipient/update-preference', {
            foodCategories: foodCategoryPreference,
            additional_notes_food_categories: foodCategoryNotes,
            timeAvailabilities: formattedAvailabilities,
            additional_notes_time_availabilities: availabilityNotes
        });

        // After successful update, fetch fresh data instead of manually updating
        const response = await axios.get('/berryAPI/recipient/profile');
        setUserProfile(response.data);
        
        setOpenFoodCategoryDialog(false);
        setOpenAvailabilityDialog(false);
    };

    return (
    <Grid container spacing={gridSpacing}>
                  
    {/* Preferences Update Section */}
    <Grid item xs={11}>
        <MainCard title={`Update Profile Preferences`} children={
            <>
                Update your food type and availability preferences below to ensure you receive the most relevant donations.
                
                {/* Food Categories Section */}
                <MainCard>
                    <Stack spacing={2}>
                        {/* Categories List */}
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography sx={{ fontWeight: 'bold' }}>
                                Food Categories:
                            </Typography>
                            <Typography>
                                {userProfile.user.food_categories?.map((category: any) => category.name).join(', ') || 'No food categories set'}
                            </Typography>
                        </Stack>
                        
                        {/* Categories Notes */}
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography sx={{ fontWeight: 'bold' }}>
                                Additional Notes:
                            </Typography>
                            <Typography>
                                {userProfile.user.additional_notes_food_categories || 'No additional notes'}
                            </Typography>
                        </Stack>

                        <Button 
                            variant="contained" 
                            onClick={() => setOpenFoodCategoryDialog(true)}
                        >
                            Update Food Preferences
                        </Button>
                    </Stack>
                </MainCard>

                {/* Time Availabilities Section */}
                <MainCard>
                    <Stack spacing={2}>
                        {/* Availabilities List */}
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography sx={{ fontWeight: 'bold' }}>
                                Time Availabilities:
                            </Typography>
                            <Typography>
                                {userProfile.user.time_availabilities.map((time: TimeAvailability) => 
                                    `${time.weekday}: ${time.start_time} - ${time.end_time}`
                                ).join(', ') || 'No availability set'}
                            </Typography>
                        </Stack>

                        {/* Availability Notes */}
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography sx={{ fontWeight: 'bold' }}>
                                Additional Notes:
                            </Typography>
                            <Typography>
                                {userProfile.user.additional_notes_time_availabilities || 'No additional notes'}
                            </Typography>
                        </Stack>

                        <Button 
                            variant="contained" 
                            onClick={() => setOpenAvailabilityDialog(true)}
                        >
                            Update Availability
                        </Button>
                    </Stack>
                </MainCard>
            </>
        }></MainCard>
    </Grid>

    {/* Preference Dialog */}
    <Dialog open={openFoodCategoryDialog} onClose={() => setOpenFoodCategoryDialog(false)}>
        <DialogTitle>Update Food Preferences</DialogTitle>
        <DialogContent sx={{ width: '400px' }}>
            <Stack spacing={2} sx={{ mt: 1 }}>
                {/* Food Categories Dropdown */}
                <FormControl fullWidth>
                    <InputLabel>Food Categories</InputLabel>
                    <Select
                        multiple
                        value={foodCategoryPreference}
                        onChange={(e) => setFoodCategoryPreference(e.target.value as number[])}
                        renderValue={(selected) => 
                            selected.map(id => foodCategories.find(category => category.id === id)?.name).join(', ')
                        }
                    >
                        {foodCategories?.map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                                <Checkbox checked={(foodCategoryPreference || []).includes(category.id)} />
                                <ListItemText primary={category.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Additional Notes */}
                <TextField
                    label="Additional Notes"
                    fullWidth
                    multiline
                    rows={4}
                    value={foodCategoryNotes}
                    onChange={(e) => setFoodCategoryNotes(e.target.value)}
                    placeholder="Enter any additional notes about your food preferences..."
                />
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                setOpenFoodCategoryDialog(false);
            }}>
                Cancel
            </Button>
            <Button onClick={handleUpdatePreference} variant="contained">
                Save
            </Button>
        </DialogActions>
    </Dialog>

    {/* Availability Dialog */}
    <Dialog open={openAvailabilityDialog} onClose={() => setOpenAvailabilityDialog(false)}>
        <DialogTitle>Update Time Availabilities</DialogTitle>
        <DialogContent sx={{ width: '600px' }}>
            <Stack spacing={2}>
                {/* Time Slots */}
                {availabilityPreference.map((timeSlot: TimeAvailability, index: number) => (
                    <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={4}>
                            {/* Weekday Select */}
                            <FormControl fullWidth>
                                <InputLabel>Weekday</InputLabel>
                                <Select
                                    value={timeSlot.weekday}
                                    onChange={(e) => {
                                        const newAvailabilities = [...availabilityPreference];
                                        newAvailabilities[index].weekday = e.target.value;
                                        setAvailabilityPreference(newAvailabilities);
                                    }}
                                >
                                    <MenuItem value="" disabled>Select Day</MenuItem>
                                    {dayOfTheWeekOptions.map((day) => (
                                        <MenuItem key={day} value={day}>{day}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Start Time */}
                        <Grid item xs={12} sm={3}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker
                                    label="Start Time"
                                    value={timeSlot.start_time}
                                    onChange={(newValue) => {
                                        const newAvailabilities = [...availabilityPreference];
                                        newAvailabilities[index].start_time = newValue;
                                        setAvailabilityPreference(newAvailabilities);
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>

                        {/* End Time */}
                        <Grid item xs={12} sm={3}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker
                                    label="End Time"
                                    value={timeSlot.end_time}
                                    onChange={(newValue) => {
                                        const newAvailabilities = [...availabilityPreference];
                                        newAvailabilities[index].end_time = newValue;
                                        setAvailabilityPreference(newAvailabilities);
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            {/* Delete Button */}
                            <IconButton 
                                onClick={() => {
                                    setAvailabilityPreference(availabilityPreference.filter((_, i) => i !== index));
                                }}
                                color="error"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
                
                {/* Add Time Availability Button */}
                <Button
                    variant="outlined"
                    onClick={() => {
                        // Add a new time availability object to the array to create a new time slot
                        setAvailabilityPreference([...availabilityPreference, {
                            weekday: '',
                            start_time: null,
                            end_time: null
                        }]);
                    }}
                >
                    Add Time Availability
                </Button>

                {/* Additional Notes */}
                <TextField
                    label="Additional Notes"
                    fullWidth
                    multiline
                    rows={4}
                    value={availabilityNotes}
                    onChange={(e) => setAvailabilityNotes(e.target.value)}
                    placeholder="Enter any additional notes about your availability..."
                />
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                setOpenAvailabilityDialog(false);
            }}>
                Cancel
            </Button>
            <Button onClick={handleUpdatePreference} variant="contained">
                Save
            </Button>
        </DialogActions>
    </Dialog>
</Grid>
);
}

export default UpdateProfilePage;
