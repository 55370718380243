import axios from 'utils/axios';
import { useCallback, useEffect, useState } from 'react';
import { IUsers } from '../types/users.interface';
// import { UserRoleId } from '../types/user.interface';

export interface UseUsersState {
    users: IUsers;
    fetchInProgress: boolean
    ready: boolean
}

export const useUsers = (
    role?: string,
    // region?: IRegionId,
    active?: boolean
) => {
    const [state, setState] = useState<UseUsersState>({
        ready: false,
        fetchInProgress: false,
        users: null
    });

    const fetch = useCallback(async () => {
        setState(s => ({ ...s, fetchInProgress: true }));

        const response = await axios.get<any>(active === false ? '/berryAPI/users/archivedusers' : '/berryAPI/users', {
          params: {
            role
          }
        });
        setState(s => ({
          ...s,
          fetchInProgress: false,
          ready: true,
          users: response.data.users
        }))
      }, [setState])

      //leaving code below for when need to deal with restoring users etc
    // useEffect(() => {
    //     (async () => {
    //         const params: { [key: string]: any } = {};
    //         if (role) {
    //             params.role = role;
    //         }
    //         // if (region) { params.region = region }
    //         var response: any;
    //         if (!deleted) {
    //             response = await axios.get('/berryAPI/users', {
    //                 // headers: { Authorization: `Bearer ${APP_INIT.apiToken}` },
    //                 params: params
    //             });
    //         } else {
    //             response = await axios.get('/berryAPI/deletedUsers', {
    //                 // headers: { Authorization: `Bearer ${APP_INIT.apiToken}` },
    //                 params: params
    //             });
    //         }

            // const deleteUser = async (userID: IUserId) => {
            //   setState(s => ({ ...s, deleteUserInProgress: true }))
            //   const updateResponse = await axios.delete(
            //     `/v2/users/${userID}`,
            //     {
            //       // headers: { Authorization: `Bearer ${APP_INIT.apiToken}` },
            //     },
            //   )
            //   setState(s => ({
            //     ...s,
            //     deleteUserInProgress: false,
            //     users: updateResponse.data.users,
            //   }))
            // }

            // const restoreUser = async (userID: IUserId) => {
            //   setState(s => ({ ...s, restoreUserInProgress: true }))
            //   const updateResponse = await axios.delete(
            //     `/v2/deletedUsers/${userID}`,
            //     {
            //       headers: { Authorization: `Bearer ${APP_INIT.apiToken}` },
            //     },
            //   )
            //   setState(s => ({
            //     ...s,
            //     restoreUserInProgress: false,
            //     users: updateResponse.data.users,
            //   }))
            // }

            // const approveUser = async (userID: IUserId) => {
            //   setState(s => ({ ...s, approveUserInProgress: true }))
            //   const updateResponse = await axios.post(
            //     `/v2/approveUser/${userID}`,
            //     {
            //       headers: { Authorization: `Bearer ${APP_INIT.apiToken}` },
            //     },
            //   )
            //   setState(s => ({
            //     ...s,
            //     approveUserInProgress: false,
            //     users: updateResponse.data.users,
            //   }))
            // }

    //         setState({
    //             ready: true,
    //             users: response.data.users
    //             // regions: response.data.regions,
    //             // deleteUserInProgress: false,
    //             // deleteUser,
    //             // restoreUserInProgress: false,
    //             // restoreUser,
    //             // approveUserInProgress: false,
    //             // approveUser,
    //         });
    //     })();
    // }, [
    //     role,
    //     deleted
    //     //  region
    // ]);

    useEffect(() => {
        fetch()
      }, [])
      return { ...state, fetch }
    };
